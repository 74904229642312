<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        class="mr-2 mb-2"
        elevation="1"
        small
        v-if="userAccess.canCreate"
        @click="dialog = true"
      >
        {{$_strings.selectioninfo.button.ADD}}
      </v-btn>
    </template>
    <v-card :loading="isLoading">
      <v-card-title>
        <h4>{{$_strings.selectioninfo.label.PAGE}}</h4>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          lazy-validation
          :disabled="!userAccess.canUpdate"
        >
          <v-row>
            <v-col>
              <v-text-field
                label="Item"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Item')]"
                v-model="form.reason"
                placeholder="Input Item"
                required
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                label="Tipe"
                v-model="form.type"
                :items="selectionTypes"
                item-text="label"
                item-value="type"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Tipe')]"
                placeholder="Tipe"
                required
                dense
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                v-if="form.type !== 'CANCELLATION'"
                style="margin:0"
                v-model="form.needConfirm"
                label="Butuh Konfirmasi"
                required
              ></v-checkbox>
              <v-checkbox
                style="margin:0"
                v-model="form.isFreeText"
                label="Free Text"
                required
              ></v-checkbox>
              <v-checkbox
                style="margin:0"
                v-model="form.isActive"
                label="Aktif"
                required
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          class="grey"
          color="white"
          text
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          color="primary"
          @click="submit"
          v-if="userAccess.canUpdate"
          :disabled="isLoading"
        >
          {{form.id ? $_strings.common.EDIT: $_strings.common.SAVE}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      dialog: false,
      selectionTypes: this.$_strings.selectioninfo.typeList,
      form: {
        // reason: '',
        type: '',
        needConfirm: false,
        isFreeText: false,
        isActive: false,
      },
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.form = {
          reason: '',
          type: '',
          needConfirm: false,
          isFreeText: false,
          isActive: false,
        };
        this.$refs.form.reset();
      }
    },
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;
      try {
        this.isLoading = true;
        await this.$_services.masterData.createEditSelectionInfo(this.form, this.form.id);
        this.$dialog.notify.success('Berhasil');
        this.$emit('fetchSelectionInfoList');
        this.dialog = false;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
